// See accessibility recommandations https://www.w3.org/WAI/tutorials/menus/flyout/

export default new class Flyout {
	initSubMenus() {
		const menuItems = document.querySelectorAll('.js-has-submenu');

		if (menuItems.length === 0) {
			return;
		}

		menuItems.forEach(function (el) {
			const button = el.querySelector('.js-open-submenu');
			if (button) {
				button.addEventListener("click", function (e) {
					e.stopImmediatePropagation();

					if (this.getAttribute('aria-expanded') === 'false') {
						const openSubmenus = document.querySelectorAll('.js-has-submenu.open');
						Array.prototype.forEach.call(openSubmenus, function (openEl) {
							openEl.classList.remove('open');
							openEl.querySelector('.js-open-submenu').setAttribute('aria-expanded', "false");
						});
						this.parentNode.classList.add('open');
						this.parentNode.querySelector('.js-open-submenu').setAttribute('aria-expanded', "true");
						this.parentNode.querySelector('[tabindex="-1"]').querySelectorAll('button').forEach(function (button) {
							button.setAttribute('tabindex', '0');
						});
					} else {
						this.parentNode.classList.remove('open');
						this.parentNode.querySelector('.js-open-submenu').setAttribute('aria-expanded', "false");
					}
					if (el.getAttribute('data-overlay') === 'true') {
						document.querySelector('.js-overlay').classList.toggle('is-opened');
					}
				});
			}
			// au clic en dehors du menu, on ferme le menu
			document.addEventListener('click', function (e) {
				e.stopPropagation();
				if (!el.contains(e.target)) {
					el.classList.remove('open');
					el.querySelector('.js-open-submenu').setAttribute('aria-expanded', "false");
				}
				if (document.querySelectorAll('.js-has-submenu.open').length === 0) {
					document.querySelector('.js-overlay').classList.remove('is-opened');
				}
			});
		});
	}
};
